import React, {useCallback, useEffect, useState} from "react";
import ajaxloader from "../../images/ajax-loader.gif";
import ReviewService from "../../services/ReviewService";
import Table from "react-bootstrap/Table";
import {Justify, Square} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {
    clearAllReviewStatusFilters,
    filterReviewStatus,
    loadAllJobs,
    selectReviewState
} from "../../reducer/reviewSlice";
import THFilter from "../canopia/THFilter";
import THSort from "../canopia/THSort";
import FilterResult from "../canopia/FilterResult";
import {SecuredDownloadLink} from "../canopia/SecuredDownloadLink";

export default function ReviewStatus(props) {

    const dispatch = useDispatch();

    const {tgtDataOrig, tgtData} = useSelector(selectReviewState);

    const [loadStatus, setLoadStatus] = useState('idle');
    const [loadMessage, setLoadMessage] = useState(null);

    const [showActionModal, setShowActionModal] = useState(false);
    const [tgtAction, setTgtAction] = useState(null);
    const [modalText, setModalText] = useState(<></>);
    const [tgtClientId, setTgtClientId] = useState(null);
    const [tgtDate, setTgtDate] = useState(null);
    const [actionRequestStatus, setActionRequestStatus] = useState('idle');
    const [messages, setMessages] = useState(null);
    const [actionErrorMessage, setActionErrorMessage] = useState(null);

    const borderLeftClassName = 'canopia-border-left';

    const load = useCallback(() => {
        dispatch(loadAllJobs());
    }, [dispatch]);

    useEffect(() => {
        if (loadStatus === "idle") {
            setLoadStatus("pending");
            try {
                load();
            } catch (err) {
                setLoadStatus("error");
                setLoadMessage("An error occurred while loading the page");
            } finally {
                setLoadStatus("success");
            }
        }
    }, [loadStatus, load]);

    const total = tgtDataOrig ? tgtDataOrig.length : 0;

    function clearAllFilters() {
        dispatch(clearAllReviewStatusFilters());
    }

    function showModal(action, clientId, date) {
        setShowActionModal(true);
        setTgtClientId(clientId);
        setTgtDate(date);
        setTgtAction(action);

        switch (action) {
            case 'create-diff':
                setModalText(<>
                    <p style={{color: 'blue'}}><h3>Diff files</h3></p>
                    <p>Create the diff file(s) between Conser and Canopia analysis files</p>
                    <p>The diff files will be saved in the same directory than the analysis files.</p>
                </>);
                break;
            case 'publish':
                setModalText(<>
                    <p style={{color: 'blue'}}><h3>Publish</h3></p>
                    <p>Are you sure you want to publish that evaluation?</p>
                    <p>The application cache will be reloaded accordingly.</p>
                </>);
                break;
            case 'publish-reports':
                setModalText(<>
                    <p style={{color: 'blue'}}><h3>Publish Reports</h3></p>
                    <p>Are you sure you want to publish the reports of that evaluation?</p>
                    <p>Please note:</p>
                    <p>Canopia will scan the Canopia_Review/YYYY-MM-DD folder and copy the following files to
                        Canopia_Final/YYYY-MM-DD.</p>
                    <ul>
                        <li>Canopia_Reports_YYYY-MM-DD.zip + extract</li>
                        <li>Canopia_Global_Report_YYYY-MM-DD.pdf</li>
                    </ul>
                    <p>The application cache will be reloaded accordingly.</p>
                </>);
                break;
            case 'download-history':
                setModalText(<>
                    <p style={{color: 'blue'}}><h3>Download history</h3></p>
                    <p>Download a csv file containing the full history of this client?</p>
                </>);
                break;
            default:
            //
        }
    }

    function hideActionModal() {
        setActionErrorMessage(null);
        setMessages(null);
        setShowActionModal(false);
    }

    function callAction() {
        setActionRequestStatus('pending');

        ReviewService.call(tgtAction, tgtClientId, tgtDate) //
            .then(
                response => {
                    setTgtClientId(null);
                    setTgtDate(null);
                    setActionRequestStatus('success');
                    setMessages(response.data ? response.data.data : null);
                    if (['publish', 'publish-reports'].includes(tgtAction)) {
                        setLoadStatus("idle"); // triggers a refresh of the page
                    } else {
                        setShowActionModal(false); // Close the modal
                    }
                },
                error => {
                    setActionRequestStatus('error');
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setActionErrorMessage(resMessage);
                }
            );
    }

    let content;
    switch (loadStatus) {
        case 'pending':
            content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
            break;
        case 'error':
            content = <div className="dashboard">
                <header className="jumbotron">
                    <h3 className='canopia'>Error</h3>
                    <p>{loadMessage}</p>
                </header>
            </div>
            break;
        case 'success':
            const colorClass = 'canopia2';
            const pfBgClass = 'background-light';

            const targetSort = "reviewStatus";

            let rows;
            let displayed = 0;
            if (tgtData) {
                displayed = tgtData.length;
                // Create the DOM objects
                rows = tgtData.map(elem => {
                    return <tr key={elem.client.id + '_' + elem.job.job1DateFmt}>
                        <td>{elem.client.displayName}</td>
                        <td>{elem.client.id}</td>
                        <td>{elem.job.job1DateFmt}</td>
                        <td>{elem.job.consensusIdInt}</td>
                        <td className={borderLeftClassName}>{elem.job.job1CreateTimestampFmt}</td>
                        <td className={borderLeftClassName}>{elem.job.job2CreateTimestampFmt}</td>
                        <td className={borderLeftClassName}>
                            {elem.job.job1PublicationStatus === 1 ? elem.job.job1PublicationTimestampFmt :
                                <span className={'label-hover'}
                                      onClick={() => showModal('publish', elem.client.id, elem.job.job1DateFmt)}
                                      style={{marginRight: '3vw'}}>
                                    <Square color={'#adad2f'} size={'1.4em'}/>
                                </span>}
                        </td>
                        <td className={borderLeftClassName}>
                            {elem.job.job1ReportPublicationStatus === 1 ? elem.job.job1ReportPublicationTimestampFmt :
                                <span className={'label-hover'}
                                      onClick={() => showModal('publish-reports', elem.client.id, elem.job.job1DateFmt)}
                                      style={{marginRight: '3vw'}}>
                                    <Square color={'#adad2f'} size={'1.4em'}/>
                                </span>}
                        </td>
                        <td className={borderLeftClassName}>
                            <span className={'label-hover'}
                                  onClick={() => showModal('create-diff', elem.client.id, elem.job.job1DateFmt)}>
                                <Justify color={'#adad2f'} size={'1.4em'}/>
                            </span>
                        </td>
                        <td className={borderLeftClassName}>
                            {/*<span className={'label-hover'}*/}
                            {/*      onClick={() => showModal('download-history', elem.client.id, elem.job.job1DateFmt)}>*/}
                            {/*    <FiletypeCsv color={'#22aa22'} size={'1.4em'}/>*/}
                            {/*</span>*/}
                            <SecuredDownloadLink client={elem.client} fileName={elem.client.name + '_History'}
                                                 method='csv'/>
                        </td>
                        <td className={borderLeftClassName}>{elem.job.job3CreateTimestampFmt}</td>
                        <td>{elem.job.job3ConsensusId}</td>
                    </tr>;
                });
            }

            const showClearAllFilters = displayed !== total;

            content = <>
                <Table hover size="sm" className={'portfolio sticky-thc'}>
                    <thead>
                    <tr>
                        <th className={colorClass + " " + pfBgClass} width={'300px'}>
                            <THFilter targetSort={targetSort} sortCol="client.displayName"
                                      filterCol="client.displayName" label="Client" showLegend={false}
                                      selectFunc={selectReviewState}
                                      filterFunc={filterReviewStatus}/>
                            <FilterResult showClearButton={showClearAllFilters}
                                          clearFunc={clearAllFilters}
                                          displayed={displayed}
                                          total={total}>
                            </FilterResult>
                        </th>
                        <th className={colorClass + " " + pfBgClass}>
                            <THSort target={targetSort} colName="client.id" label="ID" showLegend={false}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass}>
                            <THFilter targetSort={targetSort} sortCol="job.job1DateFmt" filterCol="job.job1DateFmt"
                                      label="Pf Date" showLegend={false} selectFunc={selectReviewState}
                                      filterFunc={filterReviewStatus} filterValuesSort={"desc"}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass}>
                            <THFilter targetSort={targetSort} sortCol="job.consensusIdInt" filterCol="job.consensusIdInt"
                                      label="Cons." showLegend={false} selectFunc={selectReviewState}
                                      filterFunc={filterReviewStatus} filterValuesSort={"desc"}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            <THSort target={targetSort} colName="job.job1CreateTimestampFmt" label="Initial"
                                    showLegend={false}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            <THSort target={targetSort} colName="job.job2CreateTimestampFmt" label="Review"
                                    showLegend={false}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            <THSort target={targetSort} colName="job.job1PublicationTimestampFmt" label="Publish"
                                    showLegend={false}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            <THSort target={targetSort} colName="job.job1ReportPublicationTimestampFmt"
                                    label="Publish Reports"
                                    showLegend={false}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            Diff
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            History
                        </th>
                        <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                            <THSort target={targetSort} colName="job.job3CreateTimestampFmt" label="CO2 update"
                                    showLegend={false}/>
                        </th>
                        <th className={colorClass + " " + pfBgClass}>
                            <THSort target={targetSort} colName="job.job3ConsensusId" label="Cons."
                                    showLegend={false}/>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {rows}
                    </tbody>
                </Table>
                <Modal
                    show={showActionModal}
                    onHide={() => hideActionModal()}
                    backdrop="static"
                    keyboard={false}
                    size={"lg"}
                    aria-labelledby="terms-and-conditions"
                    scrollable={true}
                >
                    <Modal.Body>
                        {modalText}
                        {messages && (
                            <div className="form-group">
                                <br/>
                                <div className={"alert alert-info"} role="alert">
                                    {messages.map(message => {
                                        return <>{message}<br/></>
                                    })}
                                </div>
                            </div>
                        )}
                        {actionErrorMessage && <p>{actionErrorMessage}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => hideActionModal()}>
                            {messages ? 'Close' : 'Cancel'}
                        </Button>
                        {!messages &&
                            <Button variant="primary" onClick={() => callAction()}
                                    disabled={actionRequestStatus === 'pending'}>
                                {actionRequestStatus === 'pending' && (
                                    <span className="spinner-border spinner-border-sm"/>
                                )}
                                Apply
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </>;
            break;
        default:
            content = <div></div>;
    }
    return (
        <div className="dashboard">
            {content}
        </div>
    );
}
