import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Filter, FilterCircle} from "react-bootstrap-icons";
import THSort from "./THSort";
import {Overlay, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {compare} from "./CanopiaUtils";

export default function THFilter(props) {
    const selectFunc = props.selectFunc;

    const {filterValuesOrig, filterValues, filterSelectedValues} = useSelector(selectFunc);

    const dispatch = useDispatch();

    const sortCol = props.sortCol;
    const filterCol = props.filterCol;
    const targetSort = props.targetSort;
    const label = props.label;
    const filterFunc = props.filterFunc;
    const filterValuesSort = props.filterValuesSort ? props.filterValuesSort : "asc";

    // Get the filter values for this column
    const colFilterValues = filterValues[filterCol];
    const colFilterSelectedValues = filterSelectedValues[filterCol];

    const target = useRef(null);

    const [filterModalDisplay, setFilterModalDisplay] = useState("hidden");

    // Tmp variables to store the user's choice
    const [selectedValuesTmp, setSelectedValuesTmp] = useState([]);
    const [selectAllCheckedTmp, setSelectAllCheckedTmp] = useState(false);

    // Init the filter
    useEffect(() => {
        if (colFilterValues) {
            setSelectAllCheckedTmp(true);
            setSelectedValuesTmp(colFilterValues);
        }
    }, [colFilterValues]);

    if (!colFilterValues || !colFilterSelectedValues) {
        return <>{label}</>;
    }

    const areAllFiltersSelected = colFilterSelectedValues.length === filterValuesOrig[filterCol].length;

    const showFilter = () => {
        setSelectAllCheckedTmp(areAllFiltersSelected);
        setSelectedValuesTmp(colFilterSelectedValues);
        setFilterModalDisplay("visible");
    };

    const hideFilter = () => {
        setFilterModalDisplay("hidden");
    };

    const addRemoveValue = (e) => {
        let value = e.target.value;

        let checked = e.target.checked;
        setSelectedValuesTmp([...selectedValuesTmp, value]);
        if (!checked) {
            setSelectedValuesTmp(selectedValuesTmp.filter(item => item.toString() !== value)); // toString() because item can be a number
            setSelectAllCheckedTmp(false);
        } else {
            // Add 1 as the tmp var is not yet set
            setSelectAllCheckedTmp(selectedValuesTmp.length + 1 >= colFilterValues.length);
        }
    };

    const selectAll = (e) => {
        let checked = e.target.checked;
        if (checked) {
            setSelectedValuesTmp(colFilterValues);
        } else {
            setSelectedValuesTmp([]);
        }
        setSelectAllCheckedTmp(checked);
    };

    function doFilter() {
        dispatch(filterFunc({colName: filterCol, selectedValues: selectedValuesTmp}));
        hideFilter();
    }

    return (
        <>
            <span style={{display: "inline"}} className={'nowrap'}>
                <THSort target={targetSort} colName={sortCol} label={label} showLegend={props.showLegend}/>
                <span ref={target} style={{display: "inline", marginLeft: "2px"}} className={'label-hover nowrap'}
                      onClick={() => showFilter()}>
                    {areAllFiltersSelected ?
                        <Filter className={"react-icons"} size={'1.4em'} style={{paddingBottom: "2px"}}/> :
                        <FilterCircle className={"react-icons canopia"} size={'1.4em'} style={{paddingBottom: "2px"}}/>}
                </span>
            </span>

            <Overlay target={target.current} rootClose={true} show={filterModalDisplay === "visible"}
                     onHide={hideFilter} placement="bottom">
                {(props) => {
                    return (
                        <Tooltip id="overlay-example" {...props}>
                            <h5 className={'canopia2'}>{label}</h5>
                            <div className={'filter-box'}>
                                <div style={{display: "block", marginTop: "12px"}}>
                                    <input type="checkbox" name={"all-cb"} onChange={selectAll}
                                           checked={selectAllCheckedTmp}/>
                                    <span style={{marginLeft: "5px"}}>(All)</span>
                                </div>
                                {[...colFilterValues].sort(function (a, b) {
                                    return compare(a, b, filterValuesSort);
                                }).map(value => {
                                    let checked = selectedValuesTmp.includes(value);
                                    return <div style={{display: "block"}} key={value}>
                                        <input
                                            key={value}
                                            type="checkbox"
                                            name={value}
                                            id={value}
                                            onChange={addRemoveValue}
                                            value={value}
                                            checked={checked}
                                        />
                                        <span style={{marginLeft: "5px"}}>{value}</span>
                                    </div>
                                })}
                            </div>
                            <div style={{margin: "12px 0 5px 0"}}>
                                <Button variant="secondary" onClick={hideFilter} size="sm">
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={() => doFilter(filterFunc)}
                                        style={{marginLeft: "8px"}} size="sm">
                                    Apply
                                </Button>
                            </div>
                        </Tooltip>
                    );
                }}
            </Overlay>
        </>
    );
}
