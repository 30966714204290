import React, {useCallback, useEffect, useState} from "react";
import ajaxloader from "../../images/ajax-loader.gif";
import {Card, DropdownButton, FormCheck} from "react-bootstrap";
import AdminService from "../../services/admin.service";
import DropdownItem from "react-bootstrap/DropdownItem";
import {useDispatch, useSelector} from "react-redux";
import {loadAllClients, selectUserState} from "../../reducer/userSlice";
import Select from "react-select";
import {inlineParams, toSelectOptions} from "../canopia/CanopiaUtils";

export default function Run(props) {

    const dispatch = useDispatch();

    const {allClients} = useSelector(selectUserState);
    // const {clientDates} = useSelector(selectDateState);

    const reviewTypes = ['POS_ID', 'ISIN_AND_NAME'];

    const [clientIds, setClientIds] = useState([]);
    const [date, setDate] = useState('');
    const [consensusId, setConsensusId] = useState('');
    const [unpublish, setUnpublish] = useState(false);
    const [runType, setRunType] = useState('full_eval_initial_run');
    const [reviewType, setReviewType] = useState(reviewTypes[0]);
    const [reviewFileName, setReviewFileName] = useState('');
    const [skipReview, setSkipReview] = useState(false);

    const [dateOptions, setDateOptions] = useState([]);

    const [loadStatus, setLoadStatus] = useState("idle");
    const [loadMessage, setLoadMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingClientDates, setLoadingClientDates] = useState(false);
    const [messages, setMessages] = useState(null);
    const [dateMessages, setDateMessages] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errors, setErrors] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const load = useCallback(() => {
        dispatch(loadAllClients());
    }, [dispatch]);

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (loadStatus === "idle") {
            setLoadStatus("pending");
            try {
                load();
            } catch (err) {
                setLoadStatus("error");
                setLoadMessage("An error occurred while loading the page");
            } finally {
                setLoadStatus("success");
            }
        }
    }, [loadStatus, load, dispatch]);

    const handleRun = (e) => {
        e.preventDefault();

        setLoading(true);
        setMessages(null);
        setErrorName(null);
        setErrors(null);
        setErrorMessage(null);

        AdminService.run(clientIds, date.value, consensusId, unpublish, runType, reviewType, reviewFileName, skipReview)
            .then(
                response => {
                    setMessages(response.data ? response.data.data : null);
                    setLoading(false);
                },
                error => {
                    setLoading(false);
                    processError(error);
                }
            );
    }

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errors;
        let errorMessage;
        if (response &&
            response.data &&
            response.data.error &&
            response.data.message) {
            errorName = response.data.error;
            errors = response.data.errors;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            errors = '';
            if (error.message) {
                console.log(error.message);
            }
        }

        setErrorName(errorName);
        setErrors(errors);
        setErrorMessage(errorMessage);
    }

    const changeRunType = (val) => {
        setRunType(val);
    }

    let content;
    if (loadStatus === "error") {
        content = <div className="main-content">
            <header className="jumbotron">
                <h3 className='canopia'>Error</h3>
                <p>{loadMessage}</p>
            </header>
        </div>
    } else if (loadStatus !== "success") {
        content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
    } else {

        let clients = [];

        allClients.forEach(client => {
            const clientId = client.id;
            const clientName = client.name;
            // let checked = selectedUserClients.includes(clientIds);
            clients.push({value: clientId, label: clientName + " (" + clientId + ")"});
        });

        const handleSelectClient = (clients, action) => {
            let tgtCientIds = [];
            clients.forEach(client => {
                tgtCientIds.push(client.value);
            });
            setClientIds(tgtCientIds);

            if (tgtCientIds.length === 0) {
                setDateOptions([]);
                setDate(null);
                return;
            }

            setLoadingClientDates(true);

            AdminService.loadClientDates({userClients: inlineParams([tgtCientIds], "userClients")})
                .then(response => {
                    const clientDates = response.data;

                    if (!clientDates || clientDates.length === 0) {
                        setDateMessages([{
                            key: "error",
                            value: "No common date found for this client set, try removing one"
                        }]);
                    } else {
                        setDateMessages(null);
                    }

                    let optionsTmp = toSelectOptions(clientDates);

                    setDateOptions(optionsTmp);
                    if (optionsTmp.length > 0) {
                        setDate(optionsTmp[0]);
                    }

                    setLoadingClientDates(false);

                }, error => {
                    setLoadingClientDates(false);
                    // processError(error);
                });

            // setClientIds([...clientIds, value]);
            // switch (action) {
            //     // case 'select-option':
            //     // case 'set-value':
            //     case 'deselect-option':
            //     case 'remove-value':
            //     case 'pop-value':
            //         setClientIds(clientIds.filter(item => item !== value));
            //         break;
            //     case 'clear':
            //        setClientIds([]);
            // }
        }

        const handleDateChange = (date, action) => {

            let dateValue;
            switch (action.action) {
                case 'create-option':
                    dateValue = date ? date.value.trim() : null;
                    if (dateValue) {
                        let tmpOptions = [...dateOptions];
                        tmpOptions.push({value: dateValue, label: dateValue});
                        tmpOptions.sort((a, b) => a.value > b.value ? -1 : 1);
                        setDateOptions(tmpOptions);
                    }
                    break;
                default:
                //
            }

            setDate(date);
        }

        const changeUnpublishChecked = () => {
            setUnpublish(!unpublish);
        }

        const changeSkipReview = () => {
            setSkipReview(!skipReview);
        }

        content = <Card style={{width: '75rem', margin: 'auto'}}>
            <Card.Body>
                <Card.Title className='canopia'>Run - Evaluate a portfolio on a specific date</Card.Title>

                <form onSubmit={handleRun}>
                    <br/>
                    <div className="form-group">
                        <label>Client</label>
                        <Select options={clients}
                                isClearable
                                isSearchable={true}
                                closeMenuOnSelect={false}
                                onChange={handleSelectClient}
                                isMulti/>
                        {/*<input value={clientIds}*/}
                        {/*       placeholder="e.g. 123"*/}
                        {/*       className={'form-control'}*/}
                        {/*       style={{width: "180px"}}*/}
                        {/*       aria-label="Client ID"*/}
                        {/*       onChange={(event) => setClientId(event.target.value)}*/}
                        {/*       aria-describedby="basic-addon2"/>*/}
                    </div>
                    <br/>
                    {clientIds.length > 0 && loadingClientDates && <div><img alt="loading" src={ajaxloader}/></div>}
                    {clientIds.length > 0 && !loadingClientDates && !dateMessages &&
                        <div className="form-group">
                            <label>Portfolio Date</label>
                            <Select options={dateOptions}
                                    placeholder={'yyyy-MM-dd'}
                                    name={'date-select'} //
                                    value={date}
                                    closeMenuOnSelect={true}
                                    onChange={handleDateChange}/>
                            <div style={{marginTop: "8px"}}>
                                Available portfolio dates taken from the uploaded portfolios (Admin -> Import -> Global
                                Custody)
                            </div>
                            <div>
                                When multiple clients are selected, the available dates are the ones shared amongst all the clients
                            </div>
                        </div>
                    }
                    {clientIds.length > 0 && dateMessages && (
                        <div className={"canopia-info"}>
                            {dateMessages.map(message => {
                                return <div className={"import_log_" + message.key}>{message.value}</div>
                            })}
                        </div>
                    )}
                    <br/>
                    <div className="form-group">
                        <label>Consensus</label>
                        <input value={consensusId}
                               placeholder="e.g. 123"
                               className={'form-control'}
                               style={{width: "180px"}}
                               aria-label="Consensus ID"
                               onChange={(event) => setConsensusId(event.target.value)}
                               aria-describedby="basic-addon2"/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Unpublish</label>
                        <input style={{marginLeft: '1rem'}} type="checkbox" name={"unpublish-cb"}
                               onChange={changeUnpublishChecked}
                               checked={unpublish}/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Run type</label>
                        <div style={{margin: '15px'}}>
                            <FormCheck type="radio" label="Initial"
                                       checked={runType === "full_eval_initial_run"}
                                       onChange={() => changeRunType("full_eval_initial_run")}/>
                            <FormCheck type="radio" label="Review"
                                       checked={runType === "full_eval_final_run"}
                                       onChange={() => changeRunType("full_eval_final_run")}/>
                            <FormCheck type="radio" label="CO2 update"
                                       checked={runType === "co2_update"}
                                       onChange={() => changeRunType("co2_update")}/>
                        </div>
                    </div>

                    {runType === "full_eval_final_run" && <>
                        <div className="form-group">
                            <p>
                                <span style={{marginRight: "5px"}}>
                                    <input type="checkbox" name={"conser-cb"}
                                           onChange={changeSkipReview}
                                           checked={skipReview}/>
                                </span>
                                Skip the review step, use the current matching in the positions (reviewed or not).
                                Typical usage: when one wants to keep the same matching but use another consensus.
                            </p>
                        </div>
                        <br/>
                        <p>The review run requires 2 more parameters:</p>
                        <ul>
                            <li>The filename of the review made by Conser</li>
                            <li>The type of review to apply: By "position ID" if available or by "ISIN and name" in case
                                you need to apply the review made for another client ID
                            </li>
                        </ul>
                        <div className="form-group">
                            <label>Review Filename (leave it blank if there is only 1 file available in the
                                directory)</label>
                            <input value={reviewFileName}
                                   placeholder="e.g. Demo Global Portfolio By Asset Class_20221230 RevisedJL.csv"
                                   className={'form-control'}
                                   aria-label="Review Filename"
                                   onChange={(event) => setReviewFileName(event.target.value)}
                                   aria-describedby="basic-addon2"/>
                        </div>
                        <br/>
                        <div className="form-group">
                            <label>Review Type</label>
                            <DropdownButton id="dropdown-basic-button" title={reviewType} value={reviewType}
                                            variant={'default-dropdown'}>
                                {reviewTypes.map(v => (
                                    v !== reviewType && <DropdownItem key={v} value={v} onClick={() => setReviewType(v)}
                                                                      bsPrefix={'canopia-dropdown-item'}>
                                        <span style={{fontWeight: 'normal'}}>{v}</span>
                                    </DropdownItem>
                                ))}
                            </DropdownButton>
                        </div>
                    </>}
                    <br/>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                            )}
                            <span>Run</span>
                        </button>
                    </div>
                    <br/>

                    {loading &&
                        <p style={{color: "red"}}>Please do not refresh the page, the process may take some time</p>}

                    {messages && (
                        <div className={"canopia-info"}>
                            {messages.map(message => {
                                return <div className={"import_log_" + message.key}>{message.value}</div>
                            })}
                        </div>
                    )}

                    {errorName && (
                        <div className="form-group">
                            <div className={"alert alert-danger"} role="alert">
                                <h3 className='canopia'>{errorName}</h3>
                                {errorMessage}
                                {errors && errors.map(error => {
                                    return <li key={error.field}>{error.defaultMessage && error.defaultMessage}</li>
                                })
                                }
                            </div>
                        </div>
                    )}
                </form>
            </Card.Body>
        </Card>
    }

    return (
        <div className="main-content">
            {content}
        </div>
    );
}
