import React, {useEffect, useRef, useState} from "react";
import {Card} from "react-bootstrap";
import AdminService from "../../services/admin.service";
import FlatSelect from "../canopia/FlatSelect";
import CreatableSelect from "react-select/creatable";
import {useDispatch, useSelector} from "react-redux";
import {loadDates, selectDateState} from "../../reducer/dateSlice";

export default function Import(props) {

    const dispatch = useDispatch();

    // const importTypes = ['Benchmark', 'Consensus'];
    // const importTypes = ['Benchmark'];
    // const importTypes = ['Global Custody', 'Benchmark', 'Real Estate', 'Fund'];
    const importTypes = [
        {key: 'fd', label: 'Fund'},
        {key: 'gc', label: 'Global Custody'},
        {key: 'cs', label: 'Consensus'},
        {key: 'bm', label: 'Benchmark'},
        {key: 're', label: 'Real Estate'}
    ];

    const {dates, dateStatus, dateError} = useSelector(selectDateState);
    const [dateOptions, setDateOptions] = useState([]);

    // Params
    const [date, setDate] = useState('');
    // const [consensusId, setConsensusId] = useState('');
    const [clientId, setClientId] = useState(-1);
    const [consensusId, setConsensusId] = useState('');
    const [importType, setImportType] = useState(importTypes[0]);
    const [source, setSource] = useState('external');

    // Either file or file name
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const inputFile = useRef(null);

    const [loading, setLoading] = useState(false);

    // Response
    const [messages, setMessages] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errors, setErrors] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (dateStatus === "idle") {
            dispatch(loadDates());
        }
    }, [dateStatus, dispatch]);

    useEffect(() => {
        let optionsTmp = [];

        dates.forEach(date => {
            const option = {value: date, label: date};
            optionsTmp.push(option);
        });

        setDateOptions(optionsTmp);
    }, [dates]);

    const handleRun = (e) => {
        e.preventDefault();

        setLoading(true);
        setMessages(null);
        setErrorName(null);
        setErrors(null);
        setErrorMessage(null);

        switch (importType.key) {
            case 'gc':
                AdminService.importGC(file)
                    .then(
                        response => {
                            setMessages(response.data ? response.data.data : null);
                            setLoading(false);
                            setFile(null);
                            if (inputFile.current) {
                                inputFile.current.value = "";
                                inputFile.current.type = "file";
                            }
                        },
                        error => {
                            setLoading(false);
                            processError(error);
                        }
                    );
                break;
            case 'cs':
                AdminService.importConsensus(consensusId, date.value)
                    .then(
                        response => {
                            setMessages(response.data ? response.data.data : null);
                            setLoading(false);
                        },
                        error => {
                            setLoading(false);
                            processError(error);
                        }
                    );
                break;
            case 'bm':
                AdminService.importBM(date.value)
                    .then(
                        response => {
                            setMessages(response.data ? response.data.data : null);
                            setLoading(false);
                        },
                        error => {
                            setLoading(false);
                            processError(error);
                        }
                    );
                break;
            case 're':
                AdminService.importRE(fileName, clientId, date.value)
                    .then(
                        response => {
                            setMessages(response.data ? response.data.data : null);
                            setLoading(false);
                        },
                        error => {
                            setLoading(false);
                            processError(error);
                        }
                    );
                break;
            case 'fd':
                AdminService.importFund(file, source)
                    .then(
                        response => {
                            setMessages(response.data ? response.data.data : null);
                            setLoading(false);
                            setFile(null);
                            if (inputFile.current) {
                                inputFile.current.value = "";
                                inputFile.current.type = "file";
                            }
                        },
                        error => {
                            setLoading(false);
                            processError(error);
                        }
                    );
                break;
            default:
                console.log("error, unknown type " + importType);
        }

    }

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errors;
        let errorMessage;
        if (response &&
            response.data &&
            response.data.error &&
            response.data.message) {
            errorName = response.data.error;
            errors = response.data.errors;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            errors = '';
            if (error.message) {
                console.log(error.message);
            }
        }

        setErrorName(errorName);
        setErrors(errors);
        setErrorMessage(errorMessage);
    }

    const handleDateChange = (date, action) => {

        let dateValue;
        switch (action.action) {
            case 'create-option':
                dateValue = date ? date.value.trim() : null;
                if (dateValue) {
                    let tmpOptions = [...dateOptions];
                    tmpOptions.push({value: dateValue, label: dateValue});
                    tmpOptions.sort((a, b) => a.value > b.value ? -1 : 1);
                    setDateOptions(tmpOptions);
                }
                break;
            default:
            //
        }

        setDate(date);
    }

    const onConsensusChange = (event) => {
        setConsensusId(event.target.value);
    }

    const onFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const onSourceChange = (event) => {
        setSource(event.target.value);
    }

    let content = <Card style={{width: '75rem', margin: 'auto'}}>
        <Card.Body>
            <Card.Title className='canopia'>Import a file</Card.Title>

            <form onSubmit={handleRun}>
                <br/>
                <div className="form-group">
                    <FlatSelect enums={importTypes} curValue={importType} curClass={'canopia-nav-link-active'}
                                otherClass={'canopia-nav-link'}
                                clickFunction={setImportType} clickParamFromEnum={true}/>
                </div>
                <br/>
                {importType.key === 'gc' && <>
                    <p>Please select a GC file to upload under Canopia_Review/CLIENT_CODE/DATE</p>
                    <div className="form-group">
                        <label>GC File</label>
                        <input type="file"
                               className={'form-control'}
                               aria-label="GC File"
                               onChange={onFileChange}
                               aria-describedby="basic-addon2"
                               ref={inputFile}/>
                    </div>
                </>}
                {importType.key === 'cs' && <>
                    <p>Please select a consensus ID to be uploaded from Canopia_Review/BM Universe/consensus/</p>
                    <div className="form-group">
                        <label>Consensus ID</label>
                        <input type="text"
                               className={'form-control'}
                               aria-label="Consensus ID"
                               onChange={onConsensusChange}
                               aria-describedby="basic-addon2"/>
                    </div>
                </>}
                {importType.key === 'bm' && <>
                    <p>Please select a date to scan all BM files containing this date in its name under Canopia_Review/BM Universe/benchmarks/</p>
                    {/*<p>Please fill in the path to the file name Canopia_Review/BM Universe/benchmarks/</p>*/}
                    {/*<div className="form-group">*/}
                    {/*    <label>Filename</label>*/}
                    {/*    <input value={fileName}*/}
                    {/*           placeholder="e.g. 2023/BM BOND UNIVERSE_20230831.csv"*/}
                    {/*           className={'form-control'}*/}
                    {/*           aria-label="Review Filename"*/}
                    {/*           onChange={(event) => setFileName(event.target.value)}*/}
                    {/*           aria-describedby="basic-addon2"/>*/}
                    {/*</div>*/}
                </>}
                {importType.key === 're' && <>
                    <p>Please fill in the path to the file name Canopia_Review/BM Universe/realestate/</p>
                    <div className="form-group">
                        <label>Filename</label>
                        <input value={fileName}
                               placeholder="e.g. 20230630_Immobilier_CH_Detail_GMutuel.xlsx"
                               className={'form-control'}
                               aria-label="Review Filename"
                               onChange={(event) => setFileName(event.target.value)}
                               aria-describedby="basic-addon2"/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Client ID</label>
                        <input value={clientId}
                               placeholder="e.g. 123"
                               className={'form-control'}
                               style={{width: "180px"}}
                               aria-label="Consensus ID"
                               onChange={(event) => setClientId(event.target.value)}
                               aria-describedby="basic-addon2"/>
                    </div>
                </>}
                {importType.key === 'fd' && <>
                    <p>Please select a fund file to upload (20Mb max)</p>
                    <div className="form-group">
                        <label>Fund File</label>
                        <input type="file"
                               className={'form-control'}
                               aria-label="Fund File"
                               onChange={onFileChange}
                               aria-describedby="basic-addon2"
                               ref={inputFile}/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Source</label>
                        <input type="text"
                               className={'form-control'}
                               aria-label="Fund Source"
                               onChange={onSourceChange}
                               value={source}
                               aria-describedby="basic-addon2"/>
                    </div>
                </>}

                {['bm', 're', 'cs'].includes(importType.key) && <>
                    <br/>
                    <div className="form-group">
                        <label>Date (yyyy-MM-dd)</label>
                        <CreatableSelect options={dateOptions}
                                         placeholder={'yyyy-MM-dd'}
                                         name={'date-select'} //
                                         value={date}
                                         closeMenuOnSelect={true}
                                         onChange={handleDateChange}/>
                    </div>
                </>}

                <br/>
                <div className="form-group">
                    <button className="btn btn-primary btn-block" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                        )}
                        <span>Import</span>
                    </button>
                </div>
                <br/>

                {loading &&
                    <p style={{color: "red"}}>Please do not refresh the page, the process may take some time</p>}

                {messages && (
                    <div className="form-group">
                        <br/>
                        <h5>Results:</h5>
                        <div className={"alert import_log"}>
                            {messages.map(message => {
                                return <div className={"import_log_" + message.key}>{message.value}</div>
                            })}
                        </div>
                    </div>
                )}

                {errorName && (
                    <div className="form-group">
                        <div className={"alert alert-danger"} role="alert">
                            <h3 className='canopia'>{errorName}</h3>
                            {errorMessage}
                            {errors && errors.map(error => {
                                return <li key={error.field}>{error.defaultMessage && error.defaultMessage}</li>
                            })
                            }
                        </div>
                    </div>
                )}
            </form>
        </Card.Body>
    </Card>;

    return (
        <div className="main-content">
            {content}
        </div>
    );
}
